<template>
  <div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 24px"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column :label="$t('dylancompanyName')" show-overflow-tooltip width="420">
          <div slot-scope="scope" class="tit">
            <div @click="gotoBooth(scope.row)" class="companyName">
              <span
                style=" color: #409EFF !important;"
                :title="
                  scope.row.company_name_en
                    | priorFormat(scope.row.company_name_zh, LOCALE)
                "
                >{{
                  scope.row.company_name_en
                    | priorFormat(scope.row.company_name_zh, LOCALE)
                }}</span
              >
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('bussinessCountryCity')">
          <span slot-scope="scope">{{
            scope.row.country
              | countryCityFormat(scope.row.city_en, scope.row.city_zh, LOCALE)
          }}</span>
        </el-table-column>
        <el-table-column :label="$t('BoothNumber')">
          <template slot-scope="scope">
            <div class="states_box">
              {{ scope.row.booth_no || "-" }}
            </div>
          </template>
        </el-table-column>

        <!-- 操作 -->
        <el-table-column :label="$t('Edit')" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="cancelFollow(scope.row)"
              >{{ $t("cancelCollection") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "conference-myCollection",
  data() {
    return {
      user_id: "",
      params_userID: "",
      title: "",
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      me_isAdmin: false,
    };
  },
  mounted() {
    this.user_id = this.$store.state.baseStore.userId;
    this.getList();
  },
  methods: {
    gotoBooth(e) {
      let routeData = this.$router.resolve({
        path: "/boothDetail",
        query: {
          company_id: e.company_id,
          booth_id: e.booth_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        user_id: this.USER_ID,
        limit: 10,
        start: this.currentPage - 1,
      };
      let result = await this.$store.dispatch(
        "baseConsole/getFollowList",
        params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.data;
        this.total = result.total;
      }
    },
    cancelFollow(e) {
      this.$confirm(this.$t("confirmCancel"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let result = await this.$store.dispatch(
            "baseConsole/handleCollectionNew",
            {
              user_id: this.USER_ID,
              booth_id: e.booth_id,
              like_type: 0,
            }
          );
          this.loading = false;
          if (result.success) {
            this.$message({
              showClose: true,
              message: this.$t("Successful_operation"),
              type: "success",
            });
            this.getList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="less">
.line {
  width: 100%;
  height: 1px;
  background: #c0c4cc;
}

.tit {
  display: flex;
  align-items: center;
  img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    cursor: pointer;
  }
  div {
    padding-left: 8px;
    cursor: pointer;
  }
  .use_name {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.85);
    max-width: 250px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  // span {
  //   max-width: 250px;
  //   height: 17px;
  //   line-height: 17px;
  //   font-size: 12px;
  //   font-weight: 400;
  //   color: #909399;

  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  //   -webkit-line-clamp: 3;
  //   overflow: hidden;
  // }
}
.states_box > div {
  display: flex;
  align-items: center;
  div {
    width: 6px;
    height: 6px;
    background: #bfbfbf;
    border-radius: 3px;
    display: inline-block;
    margin-right: 8px;
  }
  span {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #415058;
    line-height: 20px;
  }
}
.companyName {
 
}
</style>